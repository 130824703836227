import React from 'react';
import styled from 'styled-components';
import {bListArrow, wListArrow} from '../../images';
import { Card } from '../../styles';
import { Img } from '../../styles';


const MethodItem = ({ handler, active, method, ...props }) => {
	return (
		<Item
			key={method.name}
			onClick={() => {
				handler(method);
			}}
			active={active}
		>
			<N>{method.icon ?
				(<Img mW={'50px'} mH={'50px'} src={method.icon} />) :
					(method.num) + '.'
				}</N>
			<Name>
				<span>{method.name}</span>
			</Name>
		</Item>
	);
};

export default MethodItem;

MethodItem.defaultProps = {
	handler: () => {},
};

const Item = styled(Card)`
	width: 100%;
	height: 110px;
	margin: ${({ active }) => (active ? '15px 0 0' : '15px 0')};
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-content: center;
	padding: 31px 47px 34px;
	position: relative;
	box-shadow: ${({ active }) => (active ? 'none' : '0px 20px 45px rgba(0, 0, 0, 0.06)')};
	background-color: ${({ active }) => (active ? '#2E6EFF' : '#ffffff')};
	color: ${({ active }) => (active ? '#ffffff' : '#2E6EFF')};
	@media screen and (max-width: 414px) {
		padding: 37px 36px 34px;
	}

	&::after {
		content: '';
		position: absolute;
		background: url(${({ active }) => (active ? wListArrow : bListArrow)});
		background-repeat: no-repeat;
		background-size: 100%;
		width: ${({ active }) => (active ? '18px' : '10px')};
		height: ${({ active }) => (active ? '9px' : '18px')};
		right: 65px;
		top: 50%;
		transform: ${({ active }) => (active ? 'rotate(0deg) translateY(-50%)' : 'rotate(90deg) translateX(-50%)')};
		@media screen and (max-width: 414px) {
			right: 35px;
			transform: rotate(0deg) translateY(-50%);
			width: ${({ active }) => (active ? '18px' : '9px')};
			height: ${({ active }) => (active ? '9px' : '18px')};
		}
	}
`;

const Name = styled.div`
	font-style: normal;
	font-weight: bold;
	font-size: 20px;
	line-height: 24px;
	display: flex;
	align-items: center;
	margin-left: 27px;
	@media screen and (max-width: 414px) {
		font-size: 18px;
	}
`;

const N = styled.div`
	font-style: normal;
	font-weight: bold;
	font-size: 40px;
	line-height: 45px;
	@media screen and (max-width: 414px) {
		font-size: 26px;
	}
`;
