import React from 'react';
import styled from 'styled-components';
import { Button } from '../../components';
import { bCamera, bReload } from '../../images';
import { BoldText, Text } from './style';

export const MethodChooser = ({ local, remote, chooseMethod, method }) => {
	return (
		<Wrapper>
			{method ? (
				<Button click={method === 'local' ? local : remote} icon={bCamera} w={'160px'}>
					Proceed
				</Button>
			) : (
				<>
					<div>Choose method</div>
					<B>
						<Button w={'160px'} click={() => chooseMethod('local')}>
							Local
						</Button>
						<Button w={'160px'} click={() => chooseMethod('remote')}>
							Remote
						</Button>
					</B>
				</>
			)}
		</Wrapper>
	);
};

export const Repeat = ({ error, click, ageData }) => {
	return (
		<>
			{error ? (
				<BoldText>Try again</BoldText>
			) : (
				<>
					<Text>Estimated age</Text>
					<BoldText>{ageData} years</BoldText>
				</>
			)}
			<Button w={'170px'} removeBorder icon={bReload} click={click}>
				Retry
			</Button>
		</>
	);
};

const B = styled.div`
	margin-top: 10px;
	display: flex;
	flex-direction: row;
	flex-shrink: 0;
	align-items: center;
	width: 100%;
	justify-content: space-around;

	@media screen and (max-width: 414px) {
		justify-content: space-between;
	}
`;

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	flex-shrink: 0;
	align-items: center;
	width: 100%;
`;
