import React from 'react';
import styled from 'styled-components';

const DrivingResponse = ({ data }) => {
    return (
        <Ul>
            {Object.keys(data).map((key, indx, arr) => (
                <li key={key}><Span>{arr[indx]}</Span>{data[key]}</li>
            ))}
        </Ul>
    )
};

const Ul = styled.ul`
    list-style: none;
    padding: 0;
    margin: 0;
`;

const Span = styled.span`
    font-weight: bold;
    padding-right: 10px;
`;

export default DrivingResponse;
