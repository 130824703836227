import React from 'react';
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom';
import PrivateRoute from './components/PrivateRoute';
import { Login, SelectTest, AgeVerification, LivenessTest, DrivingLicence } from './scenes';
import './styles/index.scss';
import configureStore from './store/configureStore';
import rootSaga from './store/sagas';
import { Provider } from 'react-redux';

export const store = configureStore(window.__INITIAL_STATE__);
store.runSaga(rootSaga);

function App() {
	return (
		<Provider store={store}>
			<Router>
				<Switch>
					<Route path='/login' component={Login} />
					<PrivateRoute path='/age-verification' component={AgeVerification} />
					<PrivateRoute path='/liveness-test' component={LivenessTest} />
					<PrivateRoute path='/driving-license' component={DrivingLicence} />
					<PrivateRoute path='/' exact component={SelectTest} />
					<Redirect from='*' to='/' />
				</Switch>
			</Router>
		</Provider>
	);
}

export default App;
