import styled from 'styled-components';
import { overlay, focus } from '../../images';

export const camSize = {
	w: 228,
	h: 305,
	mw: 205,
	mh: 275,
};

export const drivingSizeCam = {
	w: 335,
	h: 212,
    mw: 220,
    mh: 140,
};

export const Text = styled.div`
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 20px;
`;

export const Message = styled(Text)`
	margin-bottom: 30px;
`;

export const Vignette = styled.div`
	&::before {
		position: absolute;
		content: '';
		background-image: url(${overlay});
		background-size: 100%;
		background-repeat: no-repeat;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 1;
	}
`;

export const Focus = styled.div`
	&::before {
		position: absolute;
		content: '';
		background-image: url(${focus});
		background-size: 100%;
		background-repeat: no-repeat;
		background-position: center;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 1;
	}
`;