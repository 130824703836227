import React from 'react';
import styled from 'styled-components';
import { camSize, Text } from './style';
import { Button } from '../../components';
import { bClock, rNotVerif, bReload } from '../../images';
import { SET_STEP } from './reducer';

const Result = ({ state, dispatch, ...props }) => {
	const message = state.error
		? {
				h: 'Recognition Failed',
				desc: 'Please, retake the photo or try another method to continue',
				icon: rNotVerif,
		  }
		: {
				h: 'Recognition Finished',
				desc: `in ${state.delta} sec`,
				icon: bClock,
		  };

	const retakePhoto = () => dispatch({ type: SET_STEP, step: 0 });
	return (
		<>
			<Wrap fail={state.error}>
				<Msg>{message.h}</Msg>
				<Icon icon={message.icon} />
				<Descr>{message.desc}</Descr>
			</Wrap>

			<Photo img={state.img} />

			{state.error ? (
				<Button w={'170px'} removeBorder icon={bReload} click={retakePhoto}>
					Retake Photo
				</Button>
			) : (
				<>
					<Text>Estimated age</Text>
					<AgeContainer>
						<Age>
							<Text>{state.ageData.lowerLimit} years</Text>
							<EstAge>{state.ageData.estimatedAge} years</EstAge>
							<Text>{state.ageData.higherLimit} years</Text>
						</Age>
						<UnderLine>
							<Descr>Min age</Descr>
							<Descr>Max age</Descr>
						</UnderLine>
					</AgeContainer>
					<Button w={'170px'} removeBorder click={retakePhoto}>
						<Span>Try another photo</Span>
					</Button>
				</>
			)}
		</>
	);
};

export default Result;

const Span = styled.span`
	text-decorationline: underline;
`;

const AgeContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	margin-top: 10px;
`;

const Age = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: flex-end;
	border-bottom: 5px solid #2e6eff;
	padding-bottom: 5px;
`;

const EstAge = styled.div`
	font-style: normal;
	font-weight: bold;
	font-size: 20px;
	line-height: 24px;
`;

const UnderLine = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
`;

const Msg = styled.div`
	font-style: normal;
	font-weight: bold;
	font-size: 20px;
	line-height: 24px;
	margin-bottom: 5px;
`;

const Descr = styled.div`
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 18px;
`;
const Icon = styled.div`
	width: 22px;
	height: 22px;
	background-image: url(${({ icon }) => icon});
	background-size: 100%;
	background-repeat: no-repeat;
	margin-bottom: 5px;
`;
const Wrap = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	color: ${({ fail }) => (fail ? '#FF6060' : 'inherit')};
	margin-bottom: 5px;
`;

const Photo = styled.div`
	width: ${camSize.w}px;
	height: ${camSize.h}px;
	display: block;
	overflow: hidden;
	position: relative;
	margin-bottom: 10px;
	background-image: url(${({ img }) => img});
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	transform: scaleX(-1);

	@media screen and (max-width: 414px) {
		width: ${camSize.mw}px;
		height: ${camSize.mh}px;
		margin-bottom: 20px;
	}
`;
