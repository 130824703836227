import React, { useRef, useCallback, useEffect, useReducer } from 'react';
import * as faceapi from 'face-api.js';
import { delay, arrAvg } from './helpers';
import { MethodChooser, Repeat } from './Controls';
import { camSize, Text, BoldText, Wrapper, Process, Result, Line, Camera, CamContainer } from './style';
import { Spinner } from '../../components';
import { sample } from '../../images';
import axios from 'axios';
import reducer, {
	action,
	MODELS_READY,
	CLEAR_AGE_DATA,
	SET_AGE_DATA,
	RESET_PROCESS_STATE,
	SET_PROCESS_STATE,
	SET_PROCESS_MESSAGE,
	SET_IN_PROGRESS,
	MODELS_NOT_READY,
	CHOOSE_METHOD,
	SET_TIME_DELTA,
	REMOVE_ERROR,
	ADD_ERROR,
} from './reducer';

const nPhotos = 4;
const progressParts = camSize.w / nPhotos;
const URL = process.env.REACT_APP_FACEAPI_SERVER;

const FaceApi = () => {
	const webcamRef = useRef(null);
	const [state, dispatch] = useReducer(reducer, {
		ageData: null,
		process: [],
		processMessage: 'Processing image',
		modelsReady: false,
		performance: null,
		verifying: false,
		method: '',
		error: false,
	});

	const preloadFaceapi = async image => {
		const base = new Image();
		base.src = image;
		await faceapi
			.detectSingleFace(base, new faceapi.TinyFaceDetectorOptions())
			.withAgeAndGender()
			.run();
	};

	useEffect(() => {
		const fetchModels = async () => {
			try {
				await faceapi.nets.ageGenderNet.loadFromUri('/models');
				await faceapi.nets.tinyFaceDetector.loadFromUri('/models');
				dispatch(action(MODELS_READY));
			} catch (err) {
				console.log(err);
			}
		};
		fetchModels();
	}, []);

	const startAgeRecognition = local => async () => {
		const t0 = performance.now();
		dispatch(action(REMOVE_ERROR));
		dispatch(action(CLEAR_AGE_DATA));
		dispatch(action(RESET_PROCESS_STATE));
		dispatch(action(SET_IN_PROGRESS, true));

		local ? await processImage() : await remoteProcessImage();

		const t1 = performance.now();
		const delta = Math.round(((t1 - t0) / 1000) * 10) / 10;
		dispatch(action(SET_TIME_DELTA, delta));
		dispatch(action(SET_IN_PROGRESS, false));
	};

	const remoteProcessImage = useCallback(async () => {
		dispatch(action(SET_PROCESS_MESSAGE, 'Taking photo..'));
		const arr = new Array(nPhotos);
		for (let i = 0; i < arr.length; i++) {
			await delay(200);
			arr[i] = webcamRef.current.getScreenshot();
		}
		try {
			dispatch(action(SET_PROCESS_MESSAGE, 'Waiting for response..'));
			const { data } = await axios.post(URL, { sourceImages: arr });
			if (data.hasOwnProperty('estAge')) {
				dispatch(action(SET_AGE_DATA, data.estAge));
			} else {
				dispatch(action(ADD_ERROR));
			}
		} catch (err) {
			console.log(err);
			dispatch(action(ADD_ERROR));
		}
	}, [webcamRef]);

	const processImage = useCallback(async () => {
		dispatch(action(MODELS_NOT_READY));
		await preloadFaceapi(sample);
		dispatch(action(MODELS_READY));
		let arr = [];
		let i = 0;
		while (arr.length < nPhotos) {
			i += 1;
			if (i === 20) {
				break;
			}
			await delay(100);
			try {
				const detect = await faceapi
					.detectSingleFace(webcamRef.current.video, new faceapi.TinyFaceDetectorOptions())
					.withAgeAndGender();
				console.log(detect);
				arr.push(detect);
				arr = arr.filter(Boolean);
				dispatch(action(SET_PROCESS_STATE, arr));
			} catch (err) {
				console.log(err);
			}
		}
		if (arr.length) {
			const estAge = arrAvg(arr.map(({ age }) => age));
			dispatch(action(SET_AGE_DATA, estAge));
		} else {
			dispatch(action(ADD_ERROR));
		}
	}, [webcamRef]);

	return (
		<Wrapper>
			<Process>
				{state.ageData ? (
					<Result>
						<BoldText>Recognition Finished</BoldText>
						<Text>in {state.performance} sec</Text>
					</Result>
				) : (
					state.process.map(el => <Line width={progressParts} key={el.age} />)
				)}
			</Process>
			<CamContainer>
				<Camera
					screenshotFormat={'image/jpeg'}
					audio={false}
					ref={webcamRef}
					videoConstraints={{ facingMode: 'user' }}
					screenshotQuality={1}
					minScreenshotWidth={720}
					imageSmoothing={false}
					height={305}
				/>
			</CamContainer>
			{state.modelsReady ? (
				state.verifying ? (
					<>
						<Text>{state.processMessage}</Text>
						<Spinner />
					</>
				) : state.error || state.ageData ? (
					<Repeat
						click={state.method === 'local' ? startAgeRecognition(true) : startAgeRecognition()}
						ageData={state.ageData}
						error={state.error}
					/>
				) : (
					<MethodChooser
						local={startAgeRecognition(true)}
						remote={startAgeRecognition()}
						chooseMethod={method => dispatch(action(CHOOSE_METHOD, method))}
						method={state.method}
					/>
				)
			) : (
				<Text>Please wait until the required module is loaded..</Text>
			)}
		</Wrapper>
	);
};

export default FaceApi;
