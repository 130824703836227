import React from 'react';
import { Layout, Button } from '../../components';
import { H2, Cards, C, H, D } from './SelectTest';
import { bFace, bLiveness, bLicence } from '../../images';
import { Img } from '../../styles';

const cards = [
	{
		img: bFace,
		header: ' Age Verification',
		desc: 'Take a photo of you to verify your age',
		link: '/age-verification',
	},
	{
		img: bLiveness,
		header: ' Liveness Test',
		desc: 'Check the recognition of live person and a photo',
		link: '/liveness-test',
	},

	{
		img: bLicence,
		header: 'Driving License',
		desc: 'Take a photo of Driving License to verify',
		link: '/driving-license',
	},
];

const SelectTest = () => {
	return (
		<Layout whiteLogo>
			<H2>Select a test type</H2>
			<Cards>
				{cards.map(c => {
					return (
						<C key={c.header}>
							<Img src={c.img} />
							<H>{c.header}</H>
							<D>{c.desc}</D>
							<Button to={c.link}>Start</Button>
						</C>
					);
				})}
			</Cards>
		</Layout>
	);
};

export default SelectTest;
