export const MODELS_READY = 'MODELS_READY';
export const MODELS_NOT_READY = 'MODELS_NOT_READY';
export const SET_AGE_DATA = 'SET_AGE_DATA';
export const CLEAR_AGE_DATA = 'CLEAR_AGE_DATA';
export const SET_PROCESS_STATE = 'SET_PROCESS_STATE';
export const RESET_PROCESS_STATE = 'RESET_PROCESS_STATE';
export const SET_TIME_DELTA = 'SET_TIME_DELTA';
export const SET_IN_PROGRESS = 'SET_IN_PROGRESS';
export const CHOOSE_METHOD = 'CHOOSE_METHOD';
export const ADD_ERROR = 'ADD_ERROR';
export const REMOVE_ERROR = 'REMOVE_ERROR';
export const SET_PROCESS_MESSAGE = 'SET_PROCESS_MESSAGE';

export const action = (type, data) => ({ type, data });

const reducer = (state, { type, data }) => {
	switch (type) {
		case SET_PROCESS_MESSAGE:
			return { ...state, processMessage: data };
		case ADD_ERROR:
			return { ...state, error: true };
		case REMOVE_ERROR:
			return { ...state, error: false };
		case CHOOSE_METHOD:
			return { ...state, method: data };
		case MODELS_READY:
			return { ...state, modelsReady: true };
		case MODELS_NOT_READY:
			return { ...state, modelsReady: false };
		case SET_AGE_DATA:
			return { ...state, ageData: data };
		case CLEAR_AGE_DATA:
			return { ...state, ageData: null };
		case SET_PROCESS_STATE:
			return { ...state, process: data };
		case RESET_PROCESS_STATE:
			return { ...state, process: [] };
		case SET_TIME_DELTA:
			return { ...state, performance: data };
		case SET_IN_PROGRESS:
			return { ...state, verifying: data };
		default:
			return state;
	}
};

export default reducer;
