import React from 'react';
import { MethodWrapper } from '../../components';

const MethodsList = props => {
	return (
		<MethodWrapper m={props.methods} />
	);
};

export default MethodsList;
