export const SET_IMAGE = 'SET_IMAGE';
export const NEXT_STEP = 'NEXT_STEP';
export const PREV_STEP = 'PREV_STEP';
export const SET_STEP = 'SET_STEP';
export const ADD_ERROR = 'ADD_ERROR';
export const REMOVE_ERROR = 'REMOVE_ERROR';
export const SET_DATA = 'SET_DATA';
export const SET_TIME_DELTA = 'SET_TIME_DELTA';
export const SET_ORIENTATION = 'SET_ORIENTATION';

const reducer = (state, action) => {
	switch (action.type) {
		case SET_TIME_DELTA:
			return { ...state, delta: action.delta };
		case SET_DATA:
			return { ...state, data: action.data};
		case ADD_ERROR:
			return { ...state, error: true };
		case REMOVE_ERROR:
			return { ...state, error: false };
		case SET_IMAGE:
			return { ...state, img: action.img, b64Img: action.b64Img };
		case NEXT_STEP:
			return { ...state, step: state.step + 1 };
		case PREV_STEP:
			return { ...state, step: state.step - 1 };
		case SET_STEP:
			return { ...state, step: action.step };
		case SET_ORIENTATION:
			return {...state, orientation: action.orientation};
		default:
			return state;
	}
};

export default reducer;
