import React, { useRef, useCallback } from 'react';
import { bCamera } from '../../images';
import { Button } from '../../components';
import { Message, Focus } from './style';
import Webcam from 'react-webcam';
import styled from 'styled-components';
import { NEXT_STEP, SET_IMAGE, SET_ORIENTATION } from './reducer';


const TakePhoto = ({ dispatch, state, ...props }) => {
	const webcamRef = useRef(null);

	const CamSize = {
        width: 1280,
        height: 720,
	};

    const isMobile = () => !!navigator.userAgent.match(/Android|webOS|iPhone|iPad|iPod|BlackBerry|Windows Phone|Mobile/i);

    if (isMobile()){
        const orientation = window.screen.msOrientation || window.screen.mozOrientation || (window.screen.orientation || {}).type;

        window.onorientationchange = () => {
            dispatch({ type: SET_ORIENTATION, orientation:orientation });
        };

        if(orientation === "portrait-secondary" || orientation === "portrait-primary" || orientation === undefined){
            CamSize.width = 720;
            CamSize.height = 1280;
        } else {
            CamSize.width = 1280;
            CamSize.height = 720;
        }
	}


    const videoConstraints = {
        width: CamSize.width,
        height:  CamSize.height,
        facingMode: "environment"
    };

	const b64toBlob = async b64Imge => {
		const response = await fetch(b64Imge);
		const blob = await response.blob();
		return blob;
	};

	const takePhoto = useCallback(async () => {
		const img = webcamRef.current.getScreenshot();
		const blob = await b64toBlob(img);
		const localImg = window.URL.createObjectURL(blob);
		dispatch({ type: SET_IMAGE, img: localImg, b64Img: img });
		dispatch({ type: NEXT_STEP });
	}, [webcamRef, dispatch]);

	return (
		<>
			<Message>Please take a clear picture of your Driving License</Message>
			<CamContainer>
				<Camera
					screenshotFormat={'image/jpeg'}
					audio={false}
					ref={webcamRef}
					videoConstraints={videoConstraints}
					screenshotQuality={1}
					minScreenshotWidth={CamSize.width}
                    minScreenshotHeight={CamSize.height}
					imageSmoothing={false}
				/>
			</CamContainer>
			<Button click={takePhoto} removeBorder icon={bCamera} w={'160px'}>
				Take Photo
			</Button>
		</>
	);
};

export default TakePhoto;

const Camera = styled(Webcam)`
	width: 100%;
`;

const CamContainer = styled(Focus)`
	width: 100%;
	display: block;
	overflow: hidden;
	position: relative;
	margin-bottom: 30px;
	width:100%;
`;
