import React, { useRef, useEffect, useState } from 'react';
import styled from "styled-components";
import Webcam from "react-webcam/dist/react-webcam";
import * as faceapi from 'face-api.js';
import { overlay } from '../../images';

const Messages = [
    { message: 'Please look at camera' },
    { message: 'Please, turn your head left' },
    { message: 'Please, turn your head right' },
    { message: 'Please, put your face down' },
    { message: 'Please, put your face up' },
    { message: 'Please, put your face middle' },
    { message: 'Recognition Finished' },
];

const MethodsList = () => {
    const webcamRef = useRef(null);

    const [step, setStep] = useState(0);

    useEffect(() => {
        Promise.all([
            faceapi.nets.tinyFaceDetector.loadFromUri('/models/weights'),
            faceapi.nets.faceLandmark68Net.loadFromUri('/models/weights')
        ]);
    }, []);

    let frontImageChecked = false;
    let leftImageChecked = false;
    let rightImageChecked = false;
    let downImageChecked = false;
    let upImageChecked = false;
    let modleImageChecked = false;

    let withBoxes = true;

    const LRthreshold = 4.1;
    const Fthreshold = 20;
    const maxinit = 10000;
    const mindval = 9;

    const down_threshold = 5.9; //5.9 , 5
    const up_threshold = 14;//13.77 , 15

    const normalSize = 70;

    const inputSize = 512; //512
    const scoreThreshold = 0.6;

    Array.prototype.hasMin = function (attrib) {
        return (this.length && this.reduce(function (prev, curr) {
            return prev[attrib] < curr[attrib] ? prev : curr;
        })) || null;
    };

    Array.prototype.hasMax = function (attrib) {
        return (this.length && this.reduce(function (prev, curr) {
            return prev[attrib] > curr[attrib] ? prev : curr;
        })) || null;
    };

    const distance = (x1, y1, x2, y2) => {
        return Math.sqrt(Math.pow(x1 - x2, 2) + Math.pow(y1 - y2, 2))
    };

    const onPlay = async () => {

            if (webcamRef.current) {

                const videoEl = webcamRef.current.video;

                if (videoEl.paused || videoEl.ended || !isFaceDetectionModelLoaded()) {
                    return setTimeout(() => onPlay());
                }

                const options = new faceapi.TinyFaceDetectorOptions({ inputSize, scoreThreshold });
                const results = await faceapi.detectSingleFace(videoEl, options).withFaceLandmarks();

                const canvas = window.document.getElementById('overlay');

                if (results && canvas) {

                    const dims = faceapi.matchDimensions(canvas, videoEl, true);
                    const resizedResults = faceapi.resizeResults(results, dims);

                    if (withBoxes) {
                        faceapi.draw.drawDetections(canvas, resizedResults)
                    }

                    faceapi.draw.drawFaceLandmarks(canvas, resizedResults);

                    const landmarks = resizedResults.landmarks;

                    const leftEye = landmarks.getLeftEye();
                    const rightEye = landmarks.getRightEye();
                    const jawOutline = landmarks.getJawOutline();
                    const nose = landmarks.getNose();

                    const dnose = distance(nose[6].x, nose[6].y, nose[3].x, nose[3].y);
                    const psize = distance(jawOutline[8].x, jawOutline[8].y, leftEye[1].x, leftEye[1].y);

                    const up_down = dnose / psize * 100;

                    const minLeftEye = leftEye.hasMin('x');
                    const maxRightEye = rightEye.hasMax('x');

                    const minJawOutline = jawOutline.hasMin('x');
                    //const maxJawOutline = jawOutline.hasMax('x')
                    let dleft = maxinit;
                    let dright = maxinit;
                    // let frontSide = false;

                    jawOutline.forEach(function (el) {

                        if (el.x > maxRightEye.x) {
                            const dist = distance(maxRightEye.x, maxRightEye.y, el.x, el.y);
                            if (dright > dist) {
                                dright = dist;
                            }
                        }

                        if (el.x < minLeftEye.x) {
                            const dist = distance(minLeftEye.x, minLeftEye.y, el.x, el.y);
                            if (dleft > dist) {
                                dleft = dist;
                            }
                        }
                    });

                    const dfront = Math.abs(dleft - dright);

                    const positive = minJawOutline.x > 0 && minLeftEye.x > 0;
                    const minDist = dleft > mindval && dright > mindval && dleft !== maxinit && dright !== maxinit;
                    const drt = dright * LRthreshold;
                    const dlt = dleft * LRthreshold;

                    if (psize > normalSize) {

                        if (dfront < Fthreshold && !frontImageChecked) {
                            frontImageChecked = true;
                            setStep(1);
                        }

                        if (dleft > drt && positive && minDist && !leftImageChecked && frontImageChecked) {
                            leftImageChecked = true;
                            setStep(2);
                        }

                        if (dright > dlt
                            && positive
                            && minDist
                            && !rightImageChecked
                            && frontImageChecked
                            && leftImageChecked
                        ) {
                            rightImageChecked = true;
                            setStep(3);
                        }

                        if (up_down < down_threshold
                            && positive
                            && minDist
                            && !downImageChecked
                            && frontImageChecked
                            && leftImageChecked
                            && rightImageChecked
                        ) {
                            downImageChecked = true;
                            setStep(4);
                        }

                        if (up_down > up_threshold
                            && positive
                            && minDist
                            && !upImageChecked
                            && frontImageChecked
                            && leftImageChecked
                            && rightImageChecked
                            && downImageChecked
                        ) {
                            upImageChecked = true;
                            setStep(5);
                        }

                        if (up_down > down_threshold
                            && up_down < up_threshold
                            && positive
                            && minDist
                            && !modleImageChecked
                            && frontImageChecked
                            && leftImageChecked
                            && rightImageChecked
                            && downImageChecked
                            && upImageChecked
                        ) {
                            modleImageChecked = true;
                            setStep(6);
                        }
                    }
                }

                setTimeout(() => onPlay())
            }

            return false;
        }
    ;

    const isFaceDetectionModelLoaded = () => {
        return !!getCurrentFaceDetectionNet().params
    };

    const getCurrentFaceDetectionNet = () => {
        return faceapi.nets.tinyFaceDetector
    };

    return (
        <Wrapper>
            <Message>{Messages[step].message}</Message>
            <CamContainer>
                <Camera
                    id={'test'}
                    screenshotFormat={'image/jpeg'}
                    audio={false}
                    ref={webcamRef}
                    videoConstraints={{ facingMode: 'user' }}
                    screenshotQuality={1}
                    minScreenshotWidth={720}
                    imageSmoothing={false}
                    height={305}
                    onLoadedMetadata={onPlay}
                />
                <canvas id="overlay"/>
            </CamContainer>
        </Wrapper>
    );
};

export default MethodsList;

const Camera = styled(Webcam)`
	transform: scaleX(-1) translate(50%,0);
    position: absolute;
    top: 0;
    left: 50%;
`;

const camSize = {
    w: 228,
    h: 305,
    mw: 205,
    mh: 275,
};

const Text = styled.div`
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 20px;
`;

const Message = styled(Text)`
    align-content: center;
    padding: 31px 47px 34px;
    position: relative;
    box-shadow: none;
    background-color: #2E6EFF;
    color: #ffffff;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
	margin-bottom: 30px;
`;

const Vignette = styled.div`
	&::before {
		position: absolute;
		content: '';
		background-image: url(${overlay});
		background-size: 100%;
		background-repeat: no-repeat;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 1;
	}
`;

const CamContainer = styled(Vignette)`
	width: ${camSize.w}px;
	height: ${camSize.h}px;
	display: block;
	overflow: hidden;
	position: relative;
	margin-bottom: 30px;

	@media screen and (max-width: 414px) {
		width: ${camSize.mw}px;
		height: ${camSize.mh}px;
		margin-bottom: 20px;
	}
`;

const Wrapper = styled.div`
	width: 100%;
	height: 575px;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	flex-shrink: 0;
`;