import React from 'react';
import styled from 'styled-components';
import { Button } from '../../components';
import { bClock, rNotVerif, bReload } from '../../images';
import { SET_STEP } from './reducer';
import DrivingResponse from './DrivingResponse'

const Result = ({ state, dispatch, ...props }) => {
	const message = state.error
		? {
				h: 'Recognition Failed',
				desc: 'Please, retake the photo to continue',
				icon: rNotVerif,
		  }
		: {
				h: 'Recognition Finished',
				desc: `in ${state.delta} sec`,
				icon: bClock,
		  };

	const retakePhoto = () => dispatch({ type: SET_STEP, step: 0 });
	return (
		<Container>
			<Wrap fail={state.error}>
				<Msg>{message.h}</Msg>
				<Icon icon={message.icon} />
				<Descr>{message.desc}</Descr>
			</Wrap>

			{state.error ? (
				<Button w={'170px'} removeBorder icon={bReload} click={retakePhoto}>
					Retake Photo
				</Button>
			) : (
				<>
					<ResultContainer>
						<DrivingResponse data={state.data}/>
					</ResultContainer>
					<Button w={'170px'} removeBorder click={retakePhoto}>
						<Span>Try another photo</Span>
					</Button>
				</>
			)}
		</Container>
	);
};

export default Result;

const Container = styled.div`
	overflow-y: auto;
	display: flex;
	flex-direction: column;
	align-items: center;
`;

const Span = styled.span`
	text-decorationline: underline;
`;

const ResultContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	margin-top: 10px;
`;

const Msg = styled.div`
	font-style: normal;
	font-weight: bold;
	font-size: 20px;
	line-height: 24px;
	margin-bottom: 5px;
`;

const Descr = styled.div`
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 18px;
`;
const Icon = styled.div`
	width: 22px;
	height: 22px;
	background-image: url(${({ icon }) => icon});
	background-size: 100%;
	background-repeat: no-repeat;
	margin-bottom: 5px;
`;
const Wrap = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	color: ${({ fail }) => (fail ? '#FF6060' : 'inherit')};
	margin-bottom: 5px;
`;
